<template>
  <div style="padding: 4px"></div>
</template>

<script>
export default {
  data() {
    return {
      currentValue: null,
    };
  },
  watch: {
    currentValue(newcurrentValue, oldcurrentValue) {
      this.params.filterChangedCallback();
    },
  },
  methods: {
    isFilterActive() {
      return this.currentValue !== null && this.currentValue !== undefined && this.currentValue !== false;
    },

    getModel() {
      if (this.isFilterActive()) {
        return { filterType: 'boolean', filter: true };
      } else return;
    },

    setModel(model) {
      this.currentValue = model;
    },

    myMethodForTakingValueFromFloatingFilter(value) {
      this.currentValue = value;
    },
  },
};
</script>
