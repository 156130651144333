<template>
  <span>
    <b-form-checkbox v-model="currentValue" switch @change="onInputBoxChanged()"></b-form-checkbox>
  </span>
</template>
<script>
export default {
  data() {
    return {
      currentValue: null,
    };
  },
  methods: {
    onInputBoxChanged() {
      if (this.currentValue === false) {
        // Remove the filter
        this.params.parentFilterInstance(instance => {
          instance.myMethodForTakingValueFromFloatingFilter(null);
        });
        return;
      }

      this.params.parentFilterInstance(instance => {
        instance.myMethodForTakingValueFromFloatingFilter(this.currentValue);
      });
    },

    onParentModelChanged(parentModel) {
      // When the filter is empty we will receive a null value here
      if (parentModel == null) {
        this.currentValue = '';
      } else {
        this.currentValue = parentModel.filter;
      }
    },
  },
};
</script>
